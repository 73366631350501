import React, { useEffect } from "react"
import { navigate, graphql } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

const ListQuestion = ({ data }) => {
  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/list-question")
    }
  }, [])

  const listQuestionTableData = data.allDatoCmsLabelrequirement.edges
  return (
    <React.Fragment>
      <PageTitle title={"Fragenliste A-Rating"} />
      <Layout>
        <div className="list-question-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Fragenliste A-Rating</a>
              </li>
            </ul>
          </div>
          <div className="list-question">
            <h1>Fragenliste A-Rating</h1>
            <div className="list-question__block">
              <div className="list-question__table">
                <div className="list-question__table__header">
                  <div className="list-question__table__header_cell_small">
                    ID
                  </div>
                  <div className="list-question__table__header_cell_small">
                    Frage
                  </div>
                  <div className="list-question__table__header_cell_small">
                    Anforderungskriterium
                  </div>
                </div>
                <div className="list-question__table_body">
                  {listQuestionTableData.map((row, index) => {
                    row = row.node
                    return (
                      <div className="list-question__table__row" key={index}>
                        <div className="list-question__table__cell_small">
                          {row.identifier}
                        </div>
                        <div className="list-question__table__cell_big">
                          <div className="list-question__table__cell_title">
                            Frage <br></br>
                          </div>
                          {row.question}
                        </div>
                        <div className="list-question__table__cell_big">
                          <div className="list-question__table__cell_title">
                            Anforderungskriterium <br></br>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{ __html: row.answer }}
                          ></div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default ListQuestion

export const query = graphql`
  {
    allDatoCmsLabelrequirement(
      filter: { locale: { eq: "de-DE" }, labelType: { in: ["blue", "gold"] } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          question
          answer
          identifier
        }
      }
    }
  }
`
